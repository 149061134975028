<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item label="入库时间">
        <el-date-picker v-model="searchTime" size="mini" type="datetimerange" range-separator="至"
                        start-placeholder="开始日期" :default-time="['00:00:00', '23:59:59']"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="操作时间">
        <el-date-picker v-model="optionTime" size="mini" type="datetimerange" range-separator="至"
                        start-placeholder="开始日期" :default-time="['00:00:00', '23:59:59']"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="机会ID" prop="opporId">
        <el-input v-model.trim="queryParams.opporId" placeholder="请输入机会ID" clearable size="mini"
                  type="number"/>
      </el-form-item>
      <el-form-item label="手机号" prop="phone" label-width="180">
        <el-input v-model.trim="queryParams.phone" placeholder="请输入机会手机号" clearable size="mini"/>
      </el-form-item>
      <el-form-item label="机会类型">
        <el-select v-model="queryParams.opporType" class="condition-select" multiple clearable size="mini">
          <el-option value="">请选择</el-option>
          <el-option v-for="item in opporType" :key="item.type" :label="item.name" :value="item.type">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="操作方式">
        <el-select v-model="queryParams.operType" class="condition-select" multiple clearable size="mini">
          <el-option value="">请选择</el-option>
          <el-option v-for="item in operType" :key="item.status" :label="item.name" :value="item.status">
          </el-option>
        </el-select>
      </el-form-item>
        <el-form-item label="机会池">
            <el-select v-model="queryParams.pondList" class="condition-select" filterable clearable size="mini" multiple>
                <el-option value="">请选择</el-option>
                <el-option v-for="item in pondList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
            </el-select>
        </el-form-item>
      <el-form-item label="学习顾问">
        <el-select v-model="queryParams.accountIdList" class="condition-select" filterable clearable size="mini" multiple>
          <el-option value="">请选择</el-option>
          <el-option v-for="item in adviserList" :key="item.id" :label="item.nickName" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
<!--      <el-form-item label="部门" prop="deptId">-->
<!--        <el-select v-model="queryParams.deptId" filterable clearable size="small">-->
<!--          <el-option value="">请选择</el-option>-->
<!--          <el-option-->
<!--              v-for="dept in deptList"-->
<!--              :value="dept.id"-->
<!--              :key="dept.id"-->
<!--              :label="dept.deptName"-->
<!--          ></el-option>-->
<!--        </el-select>-->
<!--      </el-form-item>-->

        <el-form-item label="团队" prop="deptId" size="mini">
            <el-cascader
                    size="mini"
                    :options="deptList"
                    :props="{ multiple: true, checkStrictly: false, label: 'title', value: 'id', children: 'children', emitPath: false}"
                    v-model="queryParams.deptIdList"
                    filterable
                    clearable>
            </el-cascader>
        </el-form-item>

      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <!--        <el-tooltip effect="light" content="导出的数据量不得超过2万条,请添加筛选条件" placement="top">-->
        <!--          <abc>-->
        <el-button type="success" icon="el-icon-download" size="mini" @click="exportOparLog">导出</el-button>
        <!--          </abc>-->
        <!--        </el-tooltip>-->
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-table v-loading="loading" :data="dataList" height="635">
      <el-table-column type="selection" width="55" align="center"/>
      <el-table-column label="机会ID" align="center" prop="id"/>
      <el-table-column label="机会手机号" align="center" prop="telephone"/>
      <el-table-column label="标识" align="center" prop="poName"/>
      <el-table-column label="机会类型" align="center" prop="opporType">
        <template slot-scope="scope">
          <span v-for="item in opporType" :key="item.type" v-if="scope.row.opporType == item.type">
            {{ item.name }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="入库时间" align="center" prop="createTime" width="150"/>
      <el-table-column label="操作用户" align="center" prop="nickName"/>
      <el-table-column label="领取位置" align="center" prop="btnName"/>
      <el-table-column label="归属部门" align="center" prop="deptName"/>
      <el-table-column label="操作方式" align="center" prop="operType">
        <template slot-scope="scope">
          <span v-for="item in operType" :key="item.status" v-if="scope.row.operType === item.status">{{ item.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作时间" align="center" prop="operTime" width="150" sortable/>
      <el-table-column label="当前机会归属人" align="center" prop="nowBelongToPerson"/>
      <el-table-column label="当前机会归属部门" align="center" prop="nowBelongToDept"/>
      <el-table-column label="操作后机会归属人" align="center" prop="afterBelongToPerson"/>
      <el-table-column label="操作后机会归属部门" align="center" prop="afterBelongToDept"/>

    </el-table>

    <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.page"
        :limit.sync="queryParams.limit"
        @pagination="getList"
    />
  </div>
</template>

<script>
import * as possessApi from "@/api/crm/possess";
import * as api from "@/utils/api";
import * as deptApi from "@/api/system/dept";
import * as accountApi from "@/api/system/account";
import * as clueApi from '@/api/clue/cluePond'
import {findTypes} from "@/utils/api";

export default {
  name: "dashboard",
  components: {},
  data() {
    return {
      pondList: [],
      searchTime: [],
      optionTime: [],
      adviserList: [],
      deptList: [],
      // 遮罩层
      loading: true,
      // 导出遮罩层
      exportLoading: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 参数配置表格数据
      dataList: [],
      // 查询参数
      queryParams: {
        page: 1,
        limit: 10,
        accountIdList: []
      },
      // 表单参数
      form: {},
      possessList: [],
      opporType: [
        {"type": 1, "name": "首咨"},
        {"type": 2, "name": "个人"},
        {"type": 3, "name": "常规"},
        {"type": 4, "name": "库存"},
        {"type": 5, "name": "唤醒"},
      ],
      operType: [
        {"type": 1, "name": "领取"},
      ]
    };
  },
  created() {
    this.findPondList()
    // window.addEventListener('keydown', this.keyboard, true)//开启监听键盘按下事件
    this.getAdviserList();
    this.getList();
    this.getPossessList();
    // this.findDept();
      this.findTreeDept();
      this.findLogTypes()
  },
  methods: {
    findLogTypes(){
      api.findTypes().then(res=>{
        this.operType = res
      })
    },
    findPondList() {
        clueApi.listCluePond({}).then(res => {
            if (res.success) {
                this.pondList = res.data
                if (this.pondList) {
                    this.activeName = this.pondList[0].code
                }
            }
          this.pondList.unshift({id:1,name:'基础池'})
        })
    },
    keyboard(e) {
        if (e.keyCode === 13) {
            this.handleQuery()
        }
    },
    findDept() {
      deptApi.list().then(res => {
        this.deptList = res.data;
      });
    },
      /**
       * 查询所有的部门信息 添加成树
       */
    findTreeDept() {
        deptApi.treeDept().then(res => {
            this.deptList = res.data
        })

    },
    exportOparLog() {
      if (!this.optionTime) {
        this.$message.warning("请先选择操作时间")
        return false
      }
      if (this.searchTime) {
        this.queryParams['startTime'] = this.searchTime[0]
        this.queryParams['endTime'] = this.searchTime[1]
      }
      if (this.optionTime) {
        this.queryParams['optionStartTime'] = this.optionTime[0]
        this.queryParams['optionEndTime'] = this.optionTime[1]
      }
      api.exportOperation(this.queryParams).then(res => {
        if (res.success) {
          this.$message.success(res.msg)
        } else {
          this.$message.error(res.msg)
        }
      })

      // const loading = this.$loading({
      //   lock: true,
      //   text: '导出数据量大,请耐心等待. Thanks♪(･ω･)ﾉ ',
      //   spinner: 'el-icon-loading',
      //   background: 'rgba(0, 0, 0, 0.7)'
      // });


      // axios({
      //   method: "post",
      //   url: "/api/manage/dashboard/operationLog",
      //   data: JSON.stringify(this.queryParams),
      //   responseType: "blob",
      //   headers: {
      //     "Content-Type": "application/json;charset=utf-8",
      //     "version": localStorage.getItem("_version"),
      //   },
      // }).then(res => {
      //   console.log(res)
      //   // loading.close();
      //   //
      //   // const blob = new Blob([res.data]);
      //   // const fileName = '操作日志.xlsx';
      //   // const elink = document.createElement('a')
      //   // elink.download = fileName;
      //   // elink.style.display = 'none';
      //   // elink.href = URL.createObjectURL(blob);
      //   // document.body.appendChild(elink);
      //   // elink.click();
      //   // URL.revokeObjectURL(elink.href); // 释放URL 对象
      //   // document.body.removeChild(elink);
      // })

    },
    getAdviserList: function () {
      accountApi.adviserList().then(res => {
        if (res.success) {
          this.adviserList = res.data
        }
      })
    },
    //获取唯一值下拉数据
    getPossessList(param) {
      possessApi.list(param).then(res => {
        this.possessList = res.data;
      })
    },
    /** 查询参数配置列表 */
    getList() {
      if (this.searchTime) {
        this.queryParams['startTime'] = this.searchTime[0]
        this.queryParams['endTime'] = this.searchTime[1]
      }
      if (!this.optionTime || this.optionTime.length === 0) {
          const now = new Date().toISOString().slice(0, 10)
          this.optionTime[0] = now + ' 00:00:00'
          this.optionTime[1] = now + ' 23:59:59'
          console.log(this.optionTime)
      }
        this.queryParams['optionStartTime'] = this.optionTime[0]
        this.queryParams['optionEndTime'] = this.optionTime[1]

      this.loading = true;
      api.operationLogPage(this.queryParams.page, this.queryParams.limit, this.queryParams).then(response => {
        this.dataList = response.data.records;
        this.total = response.data.total;
        this.loading = false;
      });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParams = {page: 1, limit: 10}
      this.resetForm("queryForm");
      this.handleQuery();
    }
  }
}
;
</script>
